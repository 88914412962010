import { useEffect, useState, useRef } from "react";
import ActivityFrame from "../components/activityFrame/ActivityFrame";
import styles from "./pld.module.scss";

// ===========================================
// 图片资源路径 - 请在这里修改所有图片路径
// ===========================================
import topPLD from './images/top_pld.png';
import topCountDown from './images/top_countdown.png';
import topLastDay from './images/top_lastday.png';

import prizeBox from './images/prize_bg.png';
import prizeSuitcase from './images/prize_suitcase.png';
import prizeCamera from './images/prize_camera.png';
import prizeCoupon from './images/prize_coupon.png';
import prizeThanks from './images/prize_thanks.png';
import prize233 from './images/prize_2.33.png';
import prize666 from './images/prize_6.66.png';
import prize999 from './images/prize_9.99.png';
import prize188 from './images/prize_18.8.png';
import prizeLucy from './images/prize_luckydraw.png';
import prizeDraw from './images/prize_draw.png';
import prizeTips from './images/prize_countTips.png';
import prizeCount1 from './images/prize_count1.png';
import prizeCount2 from './images/prize_count2.png';
import prizeCount3 from './images/prize_count3.png';

import bottomWhite from './images/bottom_white.png';
import bottomUserBg from './images/bottom_userBg.png';
import bottomUserAdd from './images/bottom_userAdd.png';
import testImg from './images/testImg.png';
import testImg2 from './images/testImg2.png';

import plgBg from './images/pld_bg_popup.png';
import plgBgStuff from './images/pld_bg_popupStuff.png';
import plgClose from './images/pld_close.png';
import plgCongrats from './images/pld_congrats.png';
import plgCongratsMoreAvatar from './images/pld_moreavatar.png';
import plgWonprize from './images/pld_wonprize.png';
import plgWonprizeLeft from './images/pld_wonprizeLeft.png';
import plgWonprizeRight from './images/pld_wonprizeRight.png';
import plgSendprize from './images/pld_sendPld.png';
import plgSorry from './images/prize_sorry.png';

import inviteButton from './images/invite_button.png';
import inviteBg from './images/invite_bg.png';
import inviteCount0 from './images/invite_count0.png';
import inviteCount1 from './images/invite_count1.png';
import inviteCount2 from './images/invite_count2.png';
import inviteCount3 from './images/invite_count3.png';
import inviteQQ from './images/invite_qq.png';
import inviteWechat from './images/invite_wechat.png';
import inviteSaveImg from './images/invite_saveImg.png';

import shareBg from './images/share_bg.png';
import shareQRcode from './images/share_qrCode.png';


import { handleBridgeResult, setupWebViewJavascriptBridge } from "../../BridgeTool";
import { useSearchParams } from "react-router-dom";
import { useToast } from "../../toastProvider/Toast";
import RetryPopupContent from "../components/retryPopupContent/RetryPopupContent";
import { platform } from "os";

enum PLDPopupType {
    Retry /// 登录态失效/初始状态获取失败
}

interface InitStatus {
    lottery: number;
    endDay: number; //结束天数
    needLotteryCount: number; //还需要抽奖几次即可中奖
    hasInviteCode: boolean; //是否填写邀请码
    isOldUser: boolean; //是否老用户
    needHelpCount: number; //还差几人助力可中奖
    inviteCode: string; //邀请码
    isPopup: boolean; //是否弹窗
    winScroll: Array<WinUser>; //中奖滚动
    helpUser: Array<HelpUser>; //助力用户列表
    successInvite: SuccessInviteUser; //成功邀请人数
}

interface WinUser {
    avatar: string;
    nickname: string;
    prize: string;
}

interface HelpUser {
    avatar: string;
    nickname: string;
}

interface SuccessInviteUser {
    total: string;
    avatars: Array<string>;
}

interface DrawStatus {
    stayGrid: number; //停留网格
    isWinner: boolean; //是否中奖
    lottery: number; //抽奖次数
    needLotteryCount: number; //还需要抽奖几次即可中奖
}

interface ExchangeStatus {
    lottery: number; //抽奖次数
}

// 抽奖页面组件
const PrizeDraw = () => {

    /// 登录态相关
    const [token, setToken] = useState<string>('');
    const [avatar, setAvatar] = useState<string>('');
    /// 网络域名  bern https://test-api.haolvheshan.cn:30011
    const requestDomain = ""
    const h5Domain = "https://wentrip.cn"

    const activityId = "4";
    const ruleUrl = encodeURIComponent(`${h5Domain}/h5/activity/pld/rule`);

    // 创建URLSearchParams对象
    const urlParams = new URLSearchParams(window.location.search);
    const isFillPopup = urlParams.get('isFillPopup'); // 'isFillPopup'

    const [imageToShare, setImageToShare] = useState<string>("");

    /// 【接口数据】
    /// 抽奖次数
    const [lottery, setLottery] = useState<number>(0);
    /// 结束天数
    const [endDay, setEndDay] = useState<number>(0);
    /// 还需要抽奖几次即可中奖
    const [needLotteryCount, setNeedLotteryCount] = useState<number>(0);
    const needLotteryCountRef = useRef<number>(0);
    /// 是否填写邀请码
    const [hasInviteCode, setHasInviteCode] = useState<boolean>(false);
    /// 是否老用户
    const [isOldUser, setIsOldUser] = useState<boolean>(false);
    /// 还差几人助力可中奖
    const [needHelpCount, setNeedHelpCount] = useState<number>(3);
    /// 邀请码
    const [inviteCode, setInviteCode] = useState<string>("");
    /// 是否弹窗
    const [isPopup, setIsPupup] = useState<boolean>(false);
    /// 中奖滚动 
    const [winScroll, setWinScroll] = useState<Array<WinUser>>([]);
    /// 助力用户列表
    const [helpUser, setHelpUser] = useState<Array<HelpUser>>([]);
    /// 成功邀请人数
    const [successInvite, setSuccessInvite] = useState<SuccessInviteUser>();


    // 状态管理
    const [isLoading, setIsLoading] = useState(false);
    const [drawCount, setDrawCount] = useState(1);
    const [extraDrawCount, setExtraDrawCount] = useState(1);
    const [showRules, setShowRules] = useState(true); // 切换显示活动规则或中奖记录
    
    // 控制弹幕的显示
    const tickerContainerRef = useRef<HTMLDivElement>(null);


    /// 弹窗样式
    const [popupType, setPopupType] = useState<PLDPopupType | undefined>(undefined);
    //弹窗
    const [showPopup, setShowPopup] = useState(false); 
    // 弹窗是否刚刚打开 主要为了让动图再播放一次
    const [isPopupJustOpened, setIsPopupJustOpened] = useState(false);
    
    // 老用户邀请成功弹窗
    const [hasShownCongratsPopup, setHasShownCongratsPopup] = useState(false);
    const [showCongratsPopup, setShowCongratsPopup] = useState(false); 
   
    // 新用户成功填写邀请码弹窗
    const [showNewUserCongratsPopup, setShowNewUserCongratsPopup] = useState(false); 
    const [showWonPrizePopup, setShowWonPrizePopup] = useState(false);
    const [showSorryPopup, setShowSorryPopup] = useState(false);
   
    // 填写邀请码弹窗
    const [showFillCodePopup, setShowFillCodePopup] = useState(false);
    // 初始化一个长度为 4 的数组，每个元素存储一个字母
    const [codes, setCodes] = useState(["", "", "", ""]);
    
    // 新用户邀请弹窗
    const [showUserInvitePopup, setShowUserInvitePopup] = useState(false);

    // 收否是点击邀请好友按钮
    const [isClickInviteButton, setIsClickInviteButton] = useState(false);
 

    // 是否中奖
    const [isWinner, setIsWinner] = useState(false);
    const isWinnerRef = useRef(false);

    // 中奖奖品
    const [stayGrid, setstayGrid] = useState(-1);
    // 根据索引获取奖品名称
    const getPrizeName = (index: number): string => {
        switch(index) {
        case 0: return '拍立得';
        case 1: return '谢谢参与';
        case 2: return '红包 ¥2.33';
        case 3: return '红包 ¥6.66';
        case 4: return '谢谢参与';
        case 5: return '红包 ¥9.99';
        case 6: return '喜茶优惠卷';
        case 7: return '红包 ¥18.8';
        case 8: return '小米行李箱';
        default: return '谢谢参与';
        }
    };
    // 根据索引获取奖品图片
    const getPrizeImage = (index: number): string => {
        switch(index) {
            case 0: return prizeCamera;
            case 1: return prizeThanks;
            case 2: return prize233;
            case 3: return prize666;
            case 4: return prizeThanks;
            case 5: return prize999;
            case 6: return prizeCoupon;
            case 7: return prize188;
            case 8: return prizeSuitcase;
            default: return prizeThanks;
        }
    };
    
    // 收否网络问题
    const [isNetWorkProblem, setIsNetWorkProblem] = useState(false);
    const isNetWorkMessage = useRef<string>("");

    // 分享平台 1- 微信 2- QQ
    const [platform, setPlatForm] = useState(1);

    // 背景图片列表
    let bgList: Array<string> = [];


    const [isInitialized, setIsInitialized] = useState(false);
    useEffect(() => {
        if (!isInitialized) {
            setup();
            setIsInitialized(true);
        }
    }, [isInitialized]);

    //进来初始化设置
    useEffect(() => {
        /// token 为 undefined 时，登录并获取token
        /// token 有值时，获取活动状态，获取头像
        /// token 为空字符串时，展示重试弹窗
        if (token.length > 0) {
            console.log("@————————token 是 1");
            updateAvatar();
            getInfo(token);
        } else if (isInitialized) {
            setPopupType(PLDPopupType.Retry);
        }
    }, [token, isInitialized]);

    // 组件卸载时清理
    useEffect(() => {
        return () => {
            if (spinTimerRef.current) {
            clearTimeout(spinTimerRef.current);
        }
    };
    }, []);

    /// 初始化
    const setup = async () => {
        setIsLoading(true);
        if (token.length === 0) {
            //Bern
            let currentToken =  await login();
            setToken(currentToken);
            if (currentToken.length === 0) {
                setPopupType(PLDPopupType.Retry);
                return;
            }
        }
    }

     /// 登录
    const login = async () => {

        return new Promise<string>((resolve, _) => {

            setIsLoading(true);
            setupWebViewJavascriptBridge((bridge: any) => {

                if (bridge === undefined) {
                    setIsLoading(false);
                    resolve('');
                } else {

                    bridge.callHandler('Hlhs_Login', null, (response: any) => {

                        let data = handleBridgeResult(response);

                        if (data === true) {

                            setupWebViewJavascriptBridge((bridge: any) => {

                                bridge.callHandler('Hlhs_Token', null, (response: any) => {
                                    let token = handleBridgeResult(response) || '';
                                    console.log(`@————————通过桥接获取到 token: ${token}`);

                                    resolve(token);
                                });
                            });
                        } else {
                            setIsLoading(false);
                            resolve('');
                        }
                    });
                }
            });
        });
    }

    /// 获取基本信息
    const getInfo = async (token: string) => {
        setIsLoading(true);

        try {
            const response = await fetch(`${requestDomain}/main/v1/polaroid`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            });
          

            if (response.ok) {
                const result = await response.json();

                if (result.code === 200 && result.data) {
                    let status = (result.data as InitStatus);

                    setLottery(status.lottery);
                    setEndDay(status.endDay);
                    setNeedLotteryCount(status.needLotteryCount);                    
                    setHasInviteCode(status.hasInviteCode);
                    setIsOldUser(status.isOldUser);
                    setNeedHelpCount(status.needHelpCount);
                    setInviteCode(status.inviteCode);
                    setIsPupup(status.isPopup);
                    setWinScroll(status.winScroll);
                    setHelpUser(status.helpUser);
                    setSuccessInvite(status.successInvite);
                   
                    console.log(`@======== isPopup: ${status.isPopup}`);
                    console.log(`@======== isOldUser: ${status.isOldUser}`);
                    console.log(`@======== successInvite.avatars: ${status.successInvite.avatars.length}`);

                    // 老用户邀请成功 恭喜弹窗
                    if (status.isPopup && status.successInvite.avatars.length > 0 && !hasShownCongratsPopup) {
                        console.log(`@======== 走了`);
                        setHasShownCongratsPopup(true);
                        handleCongratsClick();
                    }

                    // 新用户首次填写邀请码 恭喜弹窗
                    if (status.hasInviteCode && isFillPopup === "1") {
                        handleNewUserCongratsClick();
                    }

                } else {
                    console.log(`@======== getInfo message: ${result.message}`);
                    reset(); 
                }

                setIsLoading(false);
            } else {
                console.log(`@======== getInfo 响应数据无效，执行 reset`);
                reset();
            }

        } catch (err) {
            console.log(`@======== getInfo 请求出错: ${err}`);
            setIsLoading(false);
            // reset();
        }
    }

    /// 获取抽奖结果
    const getResultsDraw = async () => {

        try {
            const response = await fetch(`${requestDomain}/main/v1/polaroid/lottery`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            });

            if (response.ok) {
                const result = await response.json()

                console.log(`@   status.message result:  ${result.code} ${result.message}  ${result.message}`)

                if (result.code === 200 && result.data) {
                    let status = (result.data as DrawStatus);
                   
                    console.log(`@   status.message result: ${result.message}`);

                    console.log(`@   status.stayGrid result: ${status.stayGrid}`);
                    console.log(`@   status.isWinner result: ${status.isWinner}`);
                    console.log(`@   status.lottery result: ${status.lottery}`);
                    console.log(`@   status.needLotteryCount result: ${status.needLotteryCount}`);
                    // 减少抽奖次数
                    setLottery(status.lottery);

                    // 还需要抽奖几次即可中奖
                    
                    needLotteryCountRef.current = status.needLotteryCount;
                   
                    // 是否中奖
                    setIsWinner(status.isWinner);
                    isWinnerRef.current = status.isWinner; 
                    // 中奖奖品
                    setstayGrid(status.stayGrid);

                    // 处理中奖结果
                    switch  (status.stayGrid) {
                        case 4:
                            status.stayGrid = 5;
                            break;
                        case 5:
                            status.stayGrid = 7;
                            break;
                        case 6:
                            status.stayGrid = 8;
                            break;
                        case 7:
                            status.stayGrid = 4;
                            break;
                        case 8:
                            status.stayGrid = 6;
                            break;
                    }

                    const serverIndex = status.stayGrid;
                    // 找到这个索引在顺时针数组中的位置
                    const winIndex = clockwiseIndices.indexOf(serverIndex);
                    // 处理找不到的情况
                    const finalWinIndex = winIndex !== -1 ? winIndex : 0;
                    // 保存网络请求结果
                    networkResultReceivedRef.current = true;
                    networkWinningIndexRef.current = finalWinIndex;

                    // 检查是否已经旋转了最少时间
                    checkAndPrepareToStop();
                } else {
                    // toast(result.message)

                    isNetWorkMessage.current = result.message;
                    // 网络错误时，也设置一个随机的中奖位置以便动画能够完成
                    networkResultReceivedRef.current = true;
                    networkWinningIndexRef.current = 0;
                    // 检查是否已经旋转了最少时间
                    checkAndPrepareToStop();
                }
            } else {
                
                toast("网络请求异常")
                // 网络错误时，也设置一个随机的中奖位置以便动画能够完成
                networkResultReceivedRef.current = true;
                networkWinningIndexRef.current = 0;
                setIsNetWorkProblem(true);
                // 检查是否已经旋转了最少时间
                checkAndPrepareToStop();
            }

        } catch (err) {
            console.log(`@————————ggetResultsDraw 请求出错: ${err}`);
            setIsLoading(false);
            toast("网络请求异常")
            // 网络错误时，也设置一个随机的中奖位置以便动画能够完成
            networkResultReceivedRef.current = true;
            networkWinningIndexRef.current = 0;
            setIsNetWorkProblem(true);
            // 检查是否已经旋转了最少时间
            checkAndPrepareToStop();
        }
    }

    /// 兑换邀请码
    const exchangeCode = async () => {
        if (token.length === 0) { return toast("请先登录");  }

        let code = codes.join('');

        if (code.length === 0 ) {
            toast('邀请码不能为空！');
            return;
        }

        setIsLoading(true);
        try {
            
            console.log(`@————————exchangeCode code: ${code}`);
            const response = await fetch(`${requestDomain}/main/v1/polaroid/${code}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            });
            setIsLoading(false);
            if (response.ok) {
                const result = await response.json()
                console.log(`@   status.message result:  ${result.code} ${result.message}  ${result.message}`)
                if (result.code === 200 && result.data) {
                    let status = (result.data as ExchangeStatus);
                    console.log(`@ ExchangeStatus  status.lottery result: ${status.lottery}`);
                    setLottery(status.lottery);
                    setHasInviteCode(true);
                    // 兑换成功弹窗
                    handleNewUserCongratsClick();
                } else {
                    toast(result.message)
                }
            } else {
                toast("网络请求异常")
            }
        } catch (err) {
            console.log(`@ ---- exchangeCode 请求出错: ${err}`);
            setIsLoading(false);
            toast("网络请求异常")
        }
    }

    /// 统计分享
    const statistics = async () => {
        try {
            
            let businessFunction = (platform === 1 ? 'GIFT_POLAROID_SHARE_WX' : 'GIFT_POLAROID_SHARE_QQ');
            const response = await fetch(`${requestDomain}/main/v1/visit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    deviceType: 'h5',
                    route: '/h5/activity/pld',
                    businessModule: 'GIFT_POLAROID',
                    businessFunction: businessFunction,
                }),
            });
            console.log(`@ ---- statistics ${response.status} ${response.ok}`);
            if (response.ok) {
                const result = await response.json()
               
                if (result.code === 200 && result.data) {
                    console.log(`@ ---- statistics 请求出错: ${result.message}`);
                } else {
                    console.log(`@ ---- statistics 请求出错: ${result.message}`);
                }
            } else {
               
            }
        } catch (err) {
            console.log(`@ ---- statistics 请求出错: ${err}`);
            setIsLoading(false);
        }
    }

    const updateAvatar = () => {
        setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('Hlhs_Avatar', null, (response: any) => {
                let data = handleBridgeResult(response);
                setAvatar(data || '');
            });
        });
    }

    /// 弹窗
    const popupMaskClickHandler = () => {

        if (popupType === PLDPopupType.Retry) {
            
            return (
                <div className={styles.kflb_content_popup}>
                    <div className={styles.kflb_content_popup_content_white}>
                        <div style={{ marginTop: '50.25vw', height: '6.333vw', color: 'black', fontWeight: 600, fontSize: '4.515vw' }}>获取活动失败，请重试...</div>
                        <img style={{ marginTop: '38.08vw', width: '47.4vw' }} src={plgClose} alt="" onClick={closePopup} />
                    </div>
                </div>
            );
            
        } 
    }

    /// 真正调起分享
    useEffect(() => {

        if (imageToShare.length > 0) {

            statistics();

            let plat = platform;

            const object = {
                title: '',
                text: "", /// 弃用，兼容旧版本
                descr: "",
                imageBase64: imageToShare,
                link: '',
                platform: plat,
            };
            const objectJson = JSON.stringify(object);

            setupWebViewJavascriptBridge((bridge: any) => {
                const text = `来【问旅】抽📷拍立得啦！\n💡注册填写我的邀请码就能抽奖，每3抽必中！ \n我的邀请码：${inviteCode}`;
                console.log(`@   复制邀请码: ${inviteCode}`);
                bridge.callHandler("Hlhs_Copy", text, null);
             });

            setupWebViewJavascriptBridge((bridge: any) => {
                bridge.callHandler('Hlhs_Share', objectJson, null);
                setImageToShare("");
            });
        }
    }, [imageToShare]);

    // 分享绘制图片
    const share = async () => {
        setIsLoading(true);
        /// 文字配置
        const textConfig = [
            {
                text: inviteCode,
                x: 390,
                y: 966.66666,
                font: '600 120px PingFang SC',
                color: '#E7FF8F',
                align: 'center',
                rotate: 4, 
                 // 添加额外的文字样式
                textShadow: '0px 0px 4px rgba(231, 255, 143, 0.5)',  // 添加文字阴影
                letterSpacing: '9px',          // 字母间距
                lineHeight: '28.997px',        // 行高
                fontStyle: 'normal' 
            },
        ];
        /// 图片配置
        const imageConfig = [
            {
                src: shareBg,
                x: 0,
                y: 0,
                width: 786,
                height: 1704,
                radius: 0
            },
            {
                src: avatar,
                x: 226,
                y: 860,
                width: 42.6,
                height: 42.6,
                radius: 21
            },
            {
                src: shareQRcode,
                x: 600,
                y: 1524,
                width: 143.33,
                height: 143.33,
            },
            
        ];
        const drawText = (ctx: CanvasRenderingContext2D, textConfig: any) => {
            textConfig.forEach((config: { 
                text: string; 
                x: number; 
                y: number; 
                font: string; 
                color: string; 
                align: CanvasTextAlign; 
                rotate: number;
                letterSpacing: string;
            }) => {
                // 保存当前状态
                ctx.save();
                
                // 移动到文字位置
                ctx.translate(config.x, config.y);
                
                // 旋转（将角度转换为弧度）
                ctx.rotate(config.rotate * Math.PI / 180);
        
                // 设置文字样式
                ctx.font = config.font;
                ctx.fillStyle = config.color;
                ctx.textAlign = config.align;
                ctx.textBaseline = 'middle';  // 添加基线对齐
        
                // 添加文字阴影
                ctx.shadowColor = 'rgba(231, 255, 143, 0.5)';
                ctx.shadowBlur = 4;
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;
        
                // 绘制文字（因为已经移动了坐标系，所以在原点绘制）
                ctx.fillText(config.text, 0, 0);
        
                // 恢复之前的状态
                ctx.restore();
            });
        };
        const loadImage = (src: string) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'anonymous'; /// 解决头像跨域问题
                img.onload = () => resolve(img);
                img.onerror = reject;
                console.log(`kflb loadImage src = ${src}`);
                img.src = src;
            });
        };
        const drawImages = async (ctx: CanvasRenderingContext2D, imageConfig: any) => {
            try {
                for (const config of imageConfig) {
                    const img = await loadImage(config.src);
                    if (config.radius > 0) {
                        // 使用圆角绘制方法
                        drawRoundedImage(
                            ctx,
                            img,
                            config.x,
                            config.y,
                            config.width,
                            config.height,
                            config.radius
                        );
                    } else {
                        // 普通绘制方法
                        ctx.drawImage(img as CanvasImageSource, config.x, config.y, config.width, config.height);
                    }
                }
                return true;
            } catch (error) {
                console.log('Error loading images:', error);
                return false;
            }
        };
        // 绘制圆角矩形路径
        const drawRoundedRect = (ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number) => {
            ctx.beginPath();
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height - radius);
            ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
            ctx.lineTo(x + radius, y + height);
            ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
            ctx.closePath();
        };

        // 绘制带圆角的图片
        const drawRoundedImage = (ctx: CanvasRenderingContext2D, img: any, x: number, y: number, width: number, height: number, radius: number) => {
            ctx.save();
            drawRoundedRect(ctx, x, y, width, height, radius);
            ctx.clip();
            ctx.drawImage(img, x, y, width, height);
            ctx.restore();
        };

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            toast("分享失败，请稍后重试...")
            setIsLoading(false);
            return;
        }

        canvas.width = 786;
        canvas.height = 1704;

        try {

            /// 绘制所有图片
            const imagesDrawn = await drawImages(ctx, imageConfig);
            if (imagesDrawn === false) {
                toast("头像处理失败...");
            }
            /// 绘制文字
            drawText(ctx, textConfig);

            /// 生成最终图片
            const dataURL = canvas.toDataURL('image/png');
            setImageToShare(dataURL);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast("生成分享信息失败，请稍后重试...");
        }
    };
 
    // 处理输入框的变化
    const handleCodeChange = (index: number, value: string) => {
        console.log(`Input at index ${index} changed to: "${value}"`);
    
        setIsPopupJustOpened(false);
        
        // 确保只取一个字符，即使maxLength失效
        const singleChar = value.charAt(0);
        
        // 使用函数式更新确保我们总是基于最新的状态
        setCodes(prevCodes => {
            const newCodes = [...prevCodes];
            newCodes[index] = singleChar;
            console.log(`New codes array: ${JSON.stringify(newCodes)}`);
            return newCodes;
        });
        
        // 如果输入了值，并且不是最后一个输入框，自动聚焦到下一个输入框
        if (singleChar && index < codes.length - 1) {
            // 使用延迟以确保状态已更新
            setTimeout(() => {
                const nextInput = document.querySelector(`input[data-index="${index + 1}"]`) as HTMLInputElement;
                if (nextInput) {
                    nextInput.focus();
                }
            }, 0);
        }
    };

    // 处理按键事件
    const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
         // 如果按下的是Backspace键
        if (e.key === 'Backspace') {
            // 如果当前输入框是空的并且不是第一个输入框，则移动到上一个输入框
            if (codes[index] === '' && index > 0) {
                const prevInput = document.querySelector<HTMLInputElement>(`input[data-index="${index - 1}"]`);
                if (prevInput) {
                    prevInput.focus();
                    // 确保光标在文本最后
                    const len = prevInput.value.length;
                    prevInput.setSelectionRange(len, len);
                }
            }
        }
    };

    // 处理聚焦事件
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        // 当输入框获得焦点时，将光标移动到最后
        const target = e.target;
        const len = target.value.length;
        target.setSelectionRange(len, len);
    };
    
    //  老用户邀请成功弹窗
    const handleCongratsClick = () => {
        console.log(`@======== 走了"`);
        closePopup();
        setShowPopup(true);
        setShowCongratsPopup(true);
    };

    //  新用户成功填写邀请码弹窗
    const handleNewUserCongratsClick = () => {
        closePopup();
        setShowPopup(true);
        setShowNewUserCongratsPopup(true);
    };

    // 中奖处理
    const handleWinFunction = () => {
        setShowPopup(true);
        console.log(`@——————中奖了处理 isWinner ${isWinnerRef.current}"`);
        if (!isWinnerRef.current) {
            setShowSorryPopup(true);
        } else {
            setShowWonPrizePopup(true);
        }
    };

    // 活动规则点击
    const handlRuleClick = () => {

        //bern 
        setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('Wenlv_Route', 'webview?url=' + ruleUrl + '&ignoresSafeArea=0', null);
        });
    };

     // 中奖记录点击
    const handleRecordClick = () => {
        setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('Wenlv_Route', `community/awards?activityId=${activityId}`, null);
        });
    };
    
    // 点击抽奖次数 +1
    const handleFillCodeClick = () => {
        if (token.length === 0) { return toast("请先登录");  }
        console.log("@——————————点击了");
        closePopup();
        setShowPopup(true);

        // Bern correct
        if (!isOldUser && !hasInviteCode) {
            setShowFillCodePopup(true);
            setIsPopupJustOpened(true);
        } else {
            setShowUserInvitePopup(true);
        } 
    };

   
    // 分享处理
    const handleShareClick = (platform: number) => {
        if (token.length === 0) { return toast("请先登录");  }

       

        setPlatForm(platform);
        // 生成图片
        // share();
        if (!avatar) {
            updateAvatar();
            share();
        } else {
            share();
        }
    };

    // 关闭弹窗
    const closePopup = () => {
        setShowPopup(false);
        setShowCongratsPopup(false);
        setShowNewUserCongratsPopup(false);
        setShowWonPrizePopup(false);
        setShowSorryPopup(false);
        setShowFillCodePopup(false);
        setCodes(["", "", "", ""]);
        setShowContent(false);
        setIsClickInviteButton(false);
        setShowUserInvitePopup(false);
        // 取消中奖高亮状态
        setWinningPosition(null); 
    };
    

    // 重置
    const reset = () => {
        // clearInterval(drawingTimerIntervalId);
        setToken('');
        setAvatar('');
        setEndDay(0);
        setHasInviteCode(false);
        setIsOldUser(false);
        setNeedHelpCount(3);
        setInviteCode("");
        setIsPupup(false);
        setCodes(["", "", "", ""]);
        setIsLoading(false);
        setIsNetWorkProblem(false);
       
    }

    // toast提示
    const toast = (message: string) => {
        setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('Hlhs_Toast', message);
        });
    }
  

     // 这里主要用来处理动图展示之后才显示内容
     const [showContent, setShowContent] = useState(false);
     useEffect(() => {
         let timer: NodeJS.Timeout | number | null = null;
         
         if (showFillCodePopup || showWonPrizePopup || showNewUserCongratsPopup) {
             // 当弹窗打开时，设置延时显示内容
             timer = setTimeout(() => {
                 setShowContent(true);
             }, 1500); // 根据背景动画持续时间调整
         } else {
             // 当弹窗关闭时，重置showContent状态
             setShowContent(false);
         }
         
         return () => {
             if (timer) clearTimeout(timer);
         };
     }, [showFillCodePopup, showWonPrizePopup, showNewUserCongratsPopup]); // 依赖于showFillCodePopup的变化


     // 这里主要用来处理动图展示之后才显示内容
     const [showCongratsContent, setShowCongratsContent] = useState(false);
     useEffect(() => {
         let timer: NodeJS.Timeout | number | null = null;
         
         if (showCongratsPopup) {
             // 当弹窗打开时，设置延时显示内容
             timer = setTimeout(() => {
                setShowCongratsContent(true);
             }, 1500); // 根据背景动画持续时间调整
         } else {
             // 当弹窗关闭时，重置showContent状态
             setShowCongratsContent(false);
         }
         
         return () => {
             if (timer) clearTimeout(timer);
         };
     }, [showCongratsPopup]); // 依赖于showFillCodePopup的变化

     
     const getCountdownImage = (days: number) => {
        if (days <= 3) {
          return topLastDay; // 紧急倒计时图片(3天内)
        } else {
          return topCountDown; // 普通倒计时图片
        }
    };

    // 在组件中添加一个状态来跟踪渲染次数
    const [renderKey, setRenderKey] = useState(0);
    // 在数据更新时更新渲染key
    useEffect(() => {
        if (winScroll.length > 0) {
        setRenderKey(prev => prev + 1);
        }
    }, [winScroll]);
    // 头部组件
    const headerContent = (
        <div className={styles.header}>
           
            <div className={styles.titleContainer}>
                <img className={styles.titleImg} src={topPLD} alt="送你一人拍立得" />
                <div className={styles.badge} style={{backgroundImage: `url(${getCountdownImage(endDay)})`}}>
                    <span>{endDay}</span>
                </div>
                
            </div>
            
            <div className={styles.tickerContainer} ref={tickerContainerRef}>
                {winScroll.map((message, index) => (
                    <div 
                    key={`${renderKey}-${index}`}  // 使用renderKey确保新数据时创建全新元素
                    className={styles.tickerItem}
                    style={{ 
                        animationDelay: `${index * 5}s`
                    }}
                    >
                    <img src={message.avatar} alt="User Avatar" />
                    <span>{message.nickname} 抽中了 {message.prize}</span>
                    </div>
                ))}
            </div>
            
        </div>
    );
    



  // 10. 检查是否满足停止条件，并准备停止
    const checkAndPrepareToStop = () => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - spinStartTimeRef.current;

        console.log("检查停止条件", {
            elapsedTime,
            minSpinTimeMs,
            networkResultReceived: networkResultReceivedRef.current
        });

        if (elapsedTime >= minSpinTimeMs) {
        // 已经旋转足够时间，可以准备停止
            console.log("已旋转足够时间，准备停止");
            prepareToStop();
        } else {
            // 还没旋转够时间，设置定时器等待
            const remainingTime = minSpinTimeMs - elapsedTime;
            console.log(`还需等待 ${remainingTime}ms 才能停止`);

            setTimeout(() => {
                prepareToStop();
            }, remainingTime);
        }
    };
  
    // 11. 准备停止动画
    const prepareToStop = () => {
        if (!networkResultReceivedRef.current || networkWinningIndexRef.current === null) {
            console.log("还没收到网络结果，不能停止");
            return;
        }

        targetPositionRef.current = networkWinningIndexRef.current;
        console.log(`@———————— 准备停止动画，目标位置: ${networkWinningIndexRef.current}`);
        console.log(`@———————— 已设置targetPositionRef.current = ${targetPositionRef.current}`);
    };

    // 抽奖盒子组件
    const [isSpinning, setIsSpinning] = useState(false);
    const [currentHighlightIndex, setCurrentHighlightIndex] = useState(-1);
    const currentHighlightIndexRef = useRef<number>(-1);
    // 添加一个新的状态来跟踪中奖位置
    const [winningPosition, setWinningPosition] = useState<number | null>(null);

    // 3. 添加ref用于动画控制
    const spinTimerRef = useRef<NodeJS.Timeout | null>(null);
    const spinCountRef = useRef<number>(0);
    const spinStartTimeRef = useRef<number>(0);
    const networkResultReceivedRef = useRef<boolean>(false);
    const networkWinningIndexRef = useRef<number | null>(null);
    const minSpinTimeMs = 4000; // 最少旋转4秒
    const targetPositionRef = useRef<number | null>(null); // 新增ref来跟踪目标位置

    // 定义顺时针奖品顺序
    const clockwiseIndices = [
        9, // 下左 - 优惠券
        0,  // 左上 - 相机
        1, // 上中 - 谢谢参与 
        2, // 上中 - 谢谢参与 
        3, // 上右 - 红包6.66
        7, // 左中 - 谢谢参与
        6, // 左中 - 谢谢参与
        4, // 右中 - 红包6.66
        8, // 右中 - 红包6.66
        5, // 右下 - 行李箱
    ];

    // 点击抽奖
    const handleDraw = () => {

        if (token.length === 0) { return toast("请先登录");  }

        console.log("抽奖按钮点击", {isSpinning, lottery});
  
        // 邀请好邮按钮
        if (lottery <= 0) {
            closePopup();
            setShowPopup(true);
            setIsClickInviteButton(true);
            return;
        }

        if (isSpinning || lottery <= 0) {
           
            return;
        }
        
         // 设置初始高亮位置
        setCurrentHighlightIndex(0);
        currentHighlightIndexRef.current = 0; // 同时更新ref

        // 重置状态
        setIsSpinning(true);
        setWinningPosition(null); // 重置中奖位置
        targetPositionRef.current = null; // 重置目标位置ref
        networkResultReceivedRef.current = false;
        networkWinningIndexRef.current = null;
        spinStartTimeRef.current = Date.now();
        spinCountRef.current = 0;

        // 开始旋转动画
        startSpinAnimation();
        // 发起网络请求获取中奖结果
        getResultsDraw();
    }
    

    // 8. 实现旋转动画
    const startSpinAnimation = () => {
        console.log("startSpinAnimation开始执行");
        
        if (spinTimerRef.current) {
            clearTimeout(spinTimerRef.current);
        }
        
        // 动态速度：开始快，逐渐减慢
        const getDelay = () => {
            // 如果还没有中奖结果，保持较快的速度
            if (targetPositionRef.current === null) {
                return 80; // 快速旋转
            }
            
            // 有中奖结果，开始减速
            // 计算当前位置到中奖位置的距离
            let currentPos = currentHighlightIndexRef.current;
            let targetPos = targetPositionRef.current;
            let distance = 0;
            
            // 计算顺时针距离
            if (currentPos <= targetPos) {
                distance = targetPos - currentPos;
            } else {
                distance = clockwiseIndices.length - currentPos + targetPos;
            }

            console.log(`@———————— 当前位置: ${currentPos}, 目标位置: ${targetPos}, 距离: ${distance}`);
            
            // 根据距离调整速度，越接近目标越慢
            if (distance > 5) return 80;  // 还很远，保持快速
            if (distance > 3) return 120; // 开始减速
            if (distance > 2) return 200; // 更慢
            if (distance > 1) return 300; // 很慢
            return 400; // 最后一步，非常慢
          };
        
          const spinStep = () => {
            // 更新高亮位置
            setCurrentHighlightIndex(prev => {
                const next = (prev + 1) % clockwiseIndices.length;
                currentHighlightIndexRef.current = next; // 同步更新ref
                return next;
            });
            
            spinCountRef.current++;
            
            // 检查是否需要停止
            const shouldStop = () => {
                console.log(`@———————— shouldStop检查: targetPositionRef.current=${targetPositionRef.current}, currentHighlightIndexRef.current=${currentHighlightIndexRef.current}`);
                // 如果没有中奖位置，继续旋转
                if (targetPositionRef.current === null) {
                  return false;
                }
                
                // 如果当前位置是中奖位置，并且已经转了足够多圈，停止
                const minTurns = 2; // 最少转2圈
                const totalPositions = clockwiseIndices.length;
                const minSteps = minTurns * totalPositions;
                
                const shouldStopNow = currentHighlightIndexRef.current === targetPositionRef.current && spinCountRef.current >= minSteps;
                console.log(`是否应该停止: ${shouldStopNow} (已转步数=${spinCountRef.current}, 最少步数=${minSteps})`);
                
                // 如果应该停止，记录中奖位置
                if (shouldStopNow) {
                    setWinningPosition(targetPositionRef.current);
                }

                return  shouldStopNow;
            };
            
              if (shouldStop()) {
                // 动画完成
                console.log("动画完成，最终位置:", currentHighlightIndex);
                setIsSpinning(false);
                
                // 显示中奖弹窗
                setTimeout(() => {
                    if (isNetWorkProblem) {
                        toast("网络问题，请稍后重试...");
                    } else if (isNetWorkMessage.current.length > 0) {
                        toast(isNetWorkMessage.current);
                    } else {
                        setNeedLotteryCount(needLotteryCountRef.current);
                        handleWinFunction();
                    }
                    
                }, 500); // 稍微延迟显示弹窗，让用户看清最终结果
                
                return;
              }
            
            // 安排下一步
            const delay = getDelay();
            spinTimerRef.current = setTimeout(spinStep, delay);
          };
          
          // 开始动画
          spinStep();
        };
        
      

    













    



    // 2. 在组件内部添加以下状态变量
    const getItemClassName = (index: number) => {

        const clockwiseIndex = clockwiseIndices.indexOf(index);
        // 有三种情况可能高亮：
        // 1. 当前旋转中的高亮项
        // 2. 动画停止后的中奖项
        const isActiveSpinning = isSpinning && clockwiseIndex === currentHighlightIndexRef.current;
        const isWinningItem = !isSpinning && clockwiseIndex === winningPosition;
        
        const isActive = isActiveSpinning || isWinningItem;
        
        return `${styles.prizeItem} ${isActive ? styles.activeHighlight : ''}`;
    };

    const prizeBoxContent = (
        <div className={styles.prizeBox} style={{backgroundImage: `url(${prizeBox})`}}>
            <div className={styles.prizeGrid}>
                 {/* 相机奖品 */}
                <div className={getItemClassName(0)}>
                    <img src={prizeCamera} alt="Camera" />
                </div>
                {/* 谢谢参与 */}
                <div className={getItemClassName(1)}>
                    <img src={prizeThanks} alt="Thanks" />
                </div>
                {/* 红包奖品 */}
                <div className={getItemClassName(2)}>
                    <img src={prize233} alt="Red Packet" />
                </div>
                {/* 红包奖品 */}
                <div className={getItemClassName(3)}>
                    <img src={prize666} alt="Red Packet" />
                </div>
            
                {/* 谢谢参与 */}
                <div className={getItemClassName(5)}>
                    <img src={prizeThanks} alt="Thanks" />
                </div>
                
                 {/* 中间提示 */}
                 <div className={styles.prizeMessage}>
                    {/* 再抽1次<br/>即可中奖 */}
                    <img src={prizeTips} alt= "prizeTips" className={styles.prizeTipsImg} />
                    <img 
                    src={
                        needLotteryCount === 1 ? prizeCount1 :
                        needLotteryCount === 2? prizeCount2 :
                        prizeCount3
                    } alt= "prizeTipsCount" className={styles.prizeTipsCount} />
                </div>

                
                {/* 红包奖品 */}
                 <div className={getItemClassName(4)}>
                    <img src={prize188} alt="Red Packet" />
                </div>
                

                {/* 行李箱奖品 */}
                <div className={getItemClassName(6)}>
                    <img src={prizeSuitcase} alt="Suitcase" />
                </div>

                

                {/* 喜茶卷奖品 */}
                <div className={getItemClassName(8)}>
                    <img src={prizeCoupon} alt="Coupon" />
                </div>

                {/* 红包奖品 */} 
                <div className={getItemClassName(7)}>
                    <img src={prize999} alt="Red Packet" />
                </div>
               
            </div>
            
            <div className={styles.drawInfo}>
                <div className={styles.drawCount}>
                    <span>剩余次数: {lottery}</span>
                </div>

                <button 
                    className={styles.extraCount}
                    onClick={handleFillCodeClick}
                >
                    <img src={prizeLucy} alt="抽奖次数增加动画" />
                </button>
           
                <button 
                    className={styles.drawButton}
                    onClick={handleDraw}
                >
                    <img 
                        src={lottery === 0 ? inviteButton : prizeDraw} 
                        alt="立即抽奖动画" 
                    />
                </button>

            </div>
        </div>
    );




    // 底部组件
    const bottomContent = (
        <div className={styles.bottom} style={{backgroundImage: `url(${bottomWhite})`}} >

            <span className={styles.bottomTipsTitle}>
                {helpUser.length >= 3 
                ? "继续邀请用户获得更多机会" 
                : `${3 - helpUser.length} 人助力后必中奖`}
            </span>
            
            {/* 第一个用户位置 */}
            <div className={styles.helperUsersOne} style={{backgroundImage: `url(${bottomUserBg})`}}>
                {helpUser[0] ? (
                    <>
                        <img className={styles.helperUsersImg} src={helpUser[0].avatar} alt="用户头像"/>
                        <span className={styles.helperUsersName}>{helpUser[0].nickname || ""}</span>
                    </>
                ) : (
                    <img className={styles.helperAddImg} src={bottomUserAdd} alt="邀请用户"/>
                )}
            </div> 

            {/* 第二个用户位置 */}
            <div className={styles.helperUsersTwo} style={{backgroundImage: `url(${bottomUserBg})`}}>
                {helpUser[1] ? (
                    <>
                        <img className={styles.helperUsersImg} src={helpUser[1].avatar} alt="用户头像"/>
                        <span className={styles.helperUsersName}>{helpUser[1].nickname || ""}</span>
                    </>
                ) : (
                    <img className={styles.helperAddImg} src={bottomUserAdd} alt="邀请用户"/>
                )}
            </div> 

            {/* 第三个用户位置 */}
            <div className={styles.helperUsersThree} style={{backgroundImage: `url(${bottomUserBg})`}}>
                {helpUser[2] ? (
                        <>
                        <img className={styles.helperUsersImg} src={helpUser[2].avatar} alt="用户头像"/>
                        <span className={styles.helperUsersName}>{helpUser[2].nickname || ""}</span>
                    </>
                ) : (
                    <img className={styles.helperAddImg} src={bottomUserAdd} alt="邀请用户"/>
                )}
            </div> 
        </div>
    );

    const bottomClickArea = (
        <div className={styles.bottomClickArea} onClick={handleFillCodeClick}></div>
    ); // 底部点击区域，用于拦截点击事件，防止穿透到下层的内容

    // 底部规则和记录组件
    const bottomRuleAndRecordContent = (
        <div className={styles.bottomRuleAndRecordContainer} >
            <button 
                className={styles.ruleButton}
                onClick={handlRuleClick}
            >
               活动规则
            </button>

            <button 
                className={styles.recordButton}
                onClick={handleRecordClick}
            >
               中奖记录
            </button>
        </div>
    );

    // 弹窗内容 - 确保返回ReactElement或undefined，而不是null
    const getPopupContent = () => {

        if (!showPopup) return undefined;

        // 为每个弹窗内容添加当前时间戳作为查询参数
        const timestamp = new Date().getTime();
        // 动态构建背景图片URL
        const dynamicSuffix = isPopupJustOpened ? `?t=${timestamp}` : '';
        const dynamicBgImage = `${plgBg}${dynamicSuffix}`;
        const dynamicBgStuffImage = `${plgBgStuff}${dynamicSuffix}`;

        // 恭喜弹窗 - 老用户邀请成功 
        if (showCongratsPopup) {
            // 使用可选链操作符
            const avatarsLength = successInvite?.avatars?.length || 0;
            const avatars = successInvite?.avatars || [];
            // 最多显示前三个
            const MAX_DISPLAY = 3;
            const displayed = avatars.slice(0, MAX_DISPLAY);
            return (
                <div key="begin" className={styles.congratsPopup}>
                    <div className={styles.closeButton} onClick={closePopup}>
                        <img src={plgClose} alt="关闭" />
                    </div>

                    <div className={styles.popupCard} style={{backgroundImage: `url(${dynamicBgImage})`}}>
                        <div className={styles.popupContent}>
                        {showCongratsContent && (  // 使用条件渲染
                        <>
                            <img className={styles.congratsImg} src={plgCongrats} />

                            <p className={styles.congratsTextOne}>成功邀请了 <span className={styles.congratsNum}>{successInvite?.total}</span> 个新用户</p>
                            <p className={styles.congratsTextTwo}>获得 <span className={styles.congratsNum}>{successInvite?.total}</span> 次抽奖机会</p>

                            <div className={styles.congratsAvatar}>
                                {displayed.map((url, index) => (
                                    <img
                                        key={index}
                                        src={url}
                                        alt={`avatar-${index}`}
                                        className={styles.congratsAvatarItem}
                                        style={{ zIndex: displayed.length - index }}
                                    />
                                ))}
                                {avatarsLength > MAX_DISPLAY && (
                                    <img
                                        src={plgCongratsMoreAvatar}
                                        className={styles.congratsAvatarMore}
                                        style={{ zIndex: displayed.length - MAX_DISPLAY }}
                                    />
                                )}
                            </div>

                            <button className={styles.popupButton} onClick={closePopup}>
                                去抽奖
                            </button>
                        </>
                        )}  
                        </div>
                    </div>
                </div>
            );
        }

        // 恭喜弹窗 - 新用户首次填写邀请码
        if (showNewUserCongratsPopup) {
            return (
                <div key="begin1" className={styles.congratsPopup}>
                    <div className={styles.closeButton} onClick={closePopup}>
                        <img src={plgClose} alt="关闭" />
                    </div>

                    <div className={styles.popupCard} style={{backgroundImage: `url(${dynamicBgImage})`}}>
                        <div className={styles.popupContent}>
                        {showContent && (  // 使用条件渲染
                        <>
                            <img className={styles.congratsImg} src={plgCongrats} />

                            <p className={styles.congratsTextOne}>成功填写邀请码</p>
                            <p className={styles.congratsTextTwo}>获得 <span className={styles.congratsNum}>{1}</span> 次抽奖机会</p>

                            <button className={styles.popupButton} onClick={closePopup}>
                                去抽奖
                            </button>
                        </>
                        )}
                        </div>
                    </div>
                </div>
            );
        }

        // 中奖
        if (showWonPrizePopup) {
            return (
                <div key="continue" className={styles.congratsPopup}>
                    <div className={styles.wonPrizeLeft}  style={{ backgroundImage: `url(${plgWonprizeRight})` }} />
                    <div className={styles.wonPrizeLeft}  style={{ backgroundImage: `url(${plgWonprizeLeft})` }} />

                    <div className={styles.closeButton} onClick={closePopup}>
                        <img src={plgClose} alt="关闭" />
                    </div>

                    <div className={styles.popupCard} style={{backgroundImage: `url(${plgBg})`}}>
                        <div className={styles.popupContent}>

                        {showContent && (  // 使用条件渲染
                        <>
                            <img className={styles.wonPrizeTitleImg} src={plgWonprize} />
                            
                            <div className={styles.wonPrizeImgContainer}>
                                <img className={styles.wonPrizeImg} src={getPrizeImage(stayGrid)} />
                            </div>

                            <p className={styles.wonPrizeText2}>恭喜你获得 {getPrizeName(stayGrid)}</p>
                            <p className={styles.wonPrizeText3}>后续兑奖流程会发送在系统消息，请注意查收</p>
                           
                            <button className={styles.popupButton} onClick={closePopup}>
                                继续抽奖
                            </button>
                        </>
                        )}   
                        </div>
                    </div>
                </div>
            )
        }

        // 没有中奖
        if (showSorryPopup) {
            return (
                <div key="sorry" className={styles.sorryPopup}>
                    <img className={styles.sorryPrizeTitleImg} src={plgSorry} />
                    <button className={styles.sorryButton} onClick={closePopup}>
                        继续抽奖
                    </button>
                </div>
            )
        }

        // 填写邀请码
        if (showFillCodePopup) { 

            return (
                <div key="fillPop" className={styles.congratsPopup}>
                    <div className={styles.closeButton} onClick={closePopup}>
                        <img src={plgClose} alt="关闭" />
                    </div>  
                    
                    <div  className={styles.popupCardStuff} style={{backgroundImage: `url(${plgBgStuff})`}}>
                        <div className={styles.popupContent}>

                        {showContent && (  // 使用条件渲染
                        <>
                            <img className={styles.sendPrizeTitleImg} src={plgSendprize} />
                            <p className={styles.sendPrizeText1}>邀请新用户抽拍立得、小米行李箱、现金红包</p>

                            {/* 顶部虚线分割线 */}
                            <div className={styles.fillDashedLine}></div>

                            <p className={styles.sendPrizeText2}>填写邀请码</p>
                            <p className={styles.sendPrizeText3}>立即获得1次抽奖机会</p>
                            
                            <div className={styles.fillCardContainer}>
                                {codes.map((code: string, index: number) => (
                                <input 
                                    key={index} 
                                    type="text" // 添加type属性
                                    maxLength={1}
                                    data-index={index}
                                    className={styles.fillCard}
                                    value={code}
                                    onChange={(e) => {
                                        // 确保事件只被处理一次
                                        e.preventDefault();
                                        handleCodeChange(index, e.target.value);
                                    }}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    onFocus={handleFocus}
                                    style={{ textTransform: 'uppercase' }}
                                ></input>
                                ))}
                            </div>

                           <button className={styles.popupStuffButton} onClick={exchangeCode}>
                                填写完成，去抽奖
                            </button>
                        </>
                        )}      

                        </div>
                    </div>
                </div>
            )
        }

        // 已经填写邀请码  邀请弹窗
        if (showUserInvitePopup) {
            
            return (
                <div key="continue" className={styles.inviteBGContainer} >
                    <div className={styles.inviteCloseButton} onClick={closePopup}>
                        <img src={plgClose} alt="关闭" />
                    </div>  


                    <div className={styles.inviteBGImg} style={{backgroundImage: `url(${inviteBg})`}}>
                        <p className={styles.inviteTextOne}>
                            {isOldUser ? "你是问旅的老用户了！" : "你已经填写过邀请码了，"}
                        </p>
                        <p className={styles.inviteTextTwo}>去邀请新用户获得次数吧</p>

                        <p className={styles.inviteTextThree}>
                            {helpUser.length >= 3 ? (
                                "邀请新用户，获得更多抽奖次数"
                            ) : (
                                <>
                                    再邀请 <span className={styles.inviteTextThreeSpecial}>{3 - helpUser.length}</span> 个好友即可中奖
                                </>
                            )}
                        </p>

                        <div className={styles.inviteStatusImg} >
                            <img 
                            src={
                                helpUser.length >= 3 ? inviteCount3 :
                                helpUser.length === 2 ? inviteCount2 :
                                helpUser.length === 1 ? inviteCount1 :
                                helpUser.length === 0 ? inviteCount0 :
                                inviteCount0
                            } alt="status" />
                        </div>


                        <p className={styles.inviteTextFour}>
                            我的邀请码： <span className={styles.inviteTextFourSpecial}>{inviteCode}</span> 
                        </p>

                        <p className={styles.inviteTextFive}>
                            邀请码已复制，分享图片后直接粘贴
                        </p>
                        
                        <button 
                            className={styles.inviteWechatButton}
                            onClick={() => handleShareClick(1)}
                        >
                            <img src={inviteWechat} alt="邀请wechat好友" />
                        </button>
                        
                        <button 
                            className={styles.inviteQQButton}
                            onClick={() => handleShareClick(2)}
                        >
                            <img src={inviteQQ} alt="邀请QQ好友" />
                        </button>
                    </div>
                        

                    
                </div>
            )
        }

        // 点击邀请好友  邀请弹窗
        if (isClickInviteButton) {
            
            return (
                <div key="continue" className={styles.inviteBGContainer} >
                    <div className={styles.inviteCloseButton} onClick={closePopup}>
                        <img src={plgClose} alt="关闭" />
                    </div>  

                    <div className={styles.inviteBGImg} style={{backgroundImage: `url(${inviteBg})`}}>

                        <p className={styles.inviteFriendText}>
                            {helpUser.length >= 3 ? (
                                "邀请新用户，获得更多抽奖次数"
                            ) : (
                                <>
                                    再邀请 <span className={styles.inviteFriendTextSpecial}>{3 - helpUser.length}</span> 个好友即可中奖
                                </>
                            )}
                        </p>

                        <div className={styles.inviteFriendStatusImg} >
                            <img 
                            src={
                                helpUser.length >= 3 ? inviteCount3 :
                                helpUser.length === 2 ? inviteCount2 :
                                helpUser.length === 1 ? inviteCount1 :
                                helpUser.length === 0 ? inviteCount0 :
                                inviteCount0
                            } alt="status" />
                        </div>


                        <p className={styles.inviteTextFour}>
                            我的邀请码： <span className={styles.inviteTextFourSpecial}>{inviteCode}</span> 
                        </p>

                        <p className={styles.inviteTextFive}>
                            邀请码已复制，分享图片后直接粘贴
                        </p>
                        
                        <button 
                            className={styles.inviteWechatButton}
                            onClick={() => handleShareClick(1)}
                        >
                            <img src={inviteWechat} alt="邀请wechat好友" />
                        </button>
                        
                        <button 
                            className={styles.inviteQQButton}
                            onClick={() => handleShareClick(2)}
                        >
                            <img src={inviteQQ} alt="邀请QQ好友" />
                        </button>
                    </div>
                        

                    
                </div>
            )
        }
    };

    // 主要内容数组，传递给ActivityFrameView: Clone Active Editor
    const content = [
        headerContent,
        prizeBoxContent,
        bottomContent,
        bottomClickArea,
        bottomRuleAndRecordContent
    ];

   
    
    return (
        <ActivityFrame
            isLoading={isLoading}
            bgColor="#FFF8E1" // 浅米色背景
            bgList={bgList}
            content={content}
            popup={getPopupContent()} // 使用函数返回ReactElement或undefined
            popupMaskClickHandler={popupMaskClickHandler} // 只有在显示弹窗时才设置处理函数
        />
    );
};

export default PrizeDraw;