import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./page/Home";
import PrivacyPolicy from "./h5/docs/PrivacyPolicy";
import UserProtocol from "./h5/docs/UserProtocol";
import CommunityNorms from "./h5/docs/CommunityNorms";
import RealNameServiceAgreement from "./h5/docs/RealNameAgreement";
import Share from "./h5/Share";
import Sample from "./h5/Sample";
import Download from "./h5/Download";
import Kflb from "./h5/activity/20241227/kflb";
import KflbRule from "./h5/activity/20241227/rule";
import Xcyj from "./h5/activity/20250104/xcyj";
import Cjhd from "./h5/activity/20250114/cjhd";
import CjhdRule from "./h5/activity/20250114/rule";
import Pld from "./h5/activity/20250319/pld";
import PldRule from "./h5/activity/20250319/rule";
import CommunityProtocol from "./h5/docs/WentripCommunityProtocol";
import JSBridgeTest from "./h5/JSBridgeTest";
import CORSTest from "./h5/CORSTest";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/userProtocol" element={<UserProtocol />} />
        <Route path="/communityNorms" element={<CommunityNorms />} />
        <Route path="/share" element={<Share />} />
        <Route path="/app/sample" element={<Sample />} />
        <Route path="/h5/download" element={<Download />} />
        <Route path="/h5/activity/kflb" element={<Kflb />} />
        <Route path="/h5/activity/kflb/rule" element={<KflbRule />} />
        <Route path="/h5/activity/xcyj" element={<Xcyj />} />
        <Route path="/h5/activity/cjhd" element={<Cjhd />} />
        <Route path="/h5/activity/cjhd/rule" element={<CjhdRule />} />
        <Route path="/h5/activity/pld" element={<Pld />} />
        <Route path="/h5/activity/pld/rule" element={<PldRule />} />
        <Route path="/communityProtocol" element={<CommunityProtocol />} />
        <Route path="/h5/jsBridgeTest" element={<JSBridgeTest />} />
        <Route path="/h5/corsTest" element={<CORSTest />} />
        <Route path="/realNameAgreement" element={<RealNameServiceAgreement />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
