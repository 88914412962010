import React, { useEffect, useState } from "react";
import styles from "./kflb.module.css";
import { handleBridgeResult } from '../../BridgeTool';

import bg1 from './images/kflb_bg_1.png';
import bg2 from './images/kflb_bg_2.png';
import bg3 from './images/kflb_bg_3.png';
import bg4 from './images/kflb_bg_4.png';

import jiugonggeBgNormal from './images/kflb_icon_jiugongge_bg_normal.png';
import jiugonggeBgRuning from './images/kflb_icon_jiugongge_bg_runing.png';
import jiugonggeBgSelected from './images/kflb_icon_jiugongge_bg_selected.png';

import jiugonggeObjectThanks from './images/kflb_icon_jiugongge_object_thanks.png';
import jiugonggeObjectXicha from './images/kflb_icon_jiugongge_object_xicha.png';
import jiugonggeObjectRatioUp from './images/kflb_icon_jiugongge_object_ratio_up.png';
import jiugonggeObjectInstaxMiniSE from './images/kflb_icon_jiugongge_object_instax_mini_se.png';
import jiugonggeObjectDJIOsmoPocket3 from './images/kflb_icon_jiugongge_object_dji_osmo_pocket3.png';

import jiugonggeObjectCenterBizhong from './images/kflb_icon_jiugongge_object_center_bizhong.png';
import jiugonggeObjectCenterIcon from './images/kflb_icon_jiugongge_object_center_icon.png';
import jiugonggeObjectCenterRatioBigUp from './images/kflb_icon_jiugongge_object_center_ratio_big_up.png';
import jiugonggeObjectCenterRatioUpAgain from './images/kflb_icon_jiugongge_object_center_ratio_up_again.png';
import jiugonggeObjectCenterRatioUp from './images/kflb_icon_jiugongge_object_center_ratio_up.png';

import drawButtonIcon from './images/kflb_icon_draw_button.png';

import shareIcon from './images/kflb_icon_share.png';

import goFillInIcon from './images/kflb_icon_go_fill_in.png';
import goPostIcon from './images/kflb_icon_go_post.png';

import activityRuleIcon from './images/kflb_icon_activity_rule.png';
import myAwardIcon from './images/kflb_icon_my_award.png';

import stepCompletion from './images/kflb_icon_completion.png';

import bgPopupButtonIcon from './images/kflb_bg_popup_button.png';
import iconPopupCloseIcon from './images/kflb_icon_popup_close.png';
import iconPopupCloseBlackIcon from './images/kflb_icon_popup_close_black.png';
import textPopupShareIcon from './images/kflb_text_popup_share.png';
import popupConfirmButtonIcon from './images/kflb_icon_popup_confirm.png';

import textPopupContinueDrawIcon from './images/kflb_text_popup_continue_draw.png';
import textPopupIKnowIcon from './images/kflb_text_popup_i_know.png';
import iconPopupAwardDjiIcon from './images/kflb_icon_popup_award_dji.png';

import popupRatioUpIcon from './images/kflb_icon_popup_ratio_up.png';
import popupRatioUpBigIcon from './images/kflb_icon_popup_ratio_up_big.png';
import popupRatioUpAgainIcon from './images/kflb_icon_popup_ratio_up_again.png';
import popupRatioUpBizhongIcon from './images/kflb_icon_popup_ratio_up_bizhong.png';

import popupRatioUpFace1 from './images/kflb_icon_popup_ratio_up_face1.png';
import popupRatioUpFace2 from './images/kflb_icon_popup_ratio_up_face2.png';

import shareTemplateBg from './images/kflb_bg_share.png';

declare const window: Window & { WebViewJavascriptBridge: any, WVJBCallbacks: any };

enum KFLBBlockStatus {
    Hide, /// 隐藏
    Normal, /// 普通
    Selected, /// 选中
    Runing /// 路过
}
enum KFLBBlockObject {

    Thanks = "Thanks", /// 感谢参与
    RatioUp = "RatioUp", /// 概率提升
    Xicha = "Xicha", /// 喜茶券
    InstaxMiniSE = "InstaxMiniSE", /// 拍立得
    DJIOsmoPocket3 = "DJIOsmoPocket3", /// DJI Osmo Pocket3
    Center = "Center", /// 中心区域
    None = "None", /// 空
}
enum KFLBPopupType {

    Thanks, /// 感谢参与
    ThanksWithoutDrawCount, /// 感谢参与没有抽奖次数

    AwardXicha, /// 中奖-喜茶券
    AwardInstaxMiniSE, /// 中奖-拍立得
    AwardDJIOsmoPocket3, /// 中奖-DJI Osmo Pocket3

    RatioUp, /// 概率提升
    RatioUpAgain, /// 概率再提升
    RatioUpBig, /// 概率大提升
    RatioUpBizhong, /// 必中

    NotEnoughDrawCount, /// 没有抽奖次数了
    FillInInviteCode, /// 填写邀请码

    Retry /// 登录态失效/初始状态获取失败
}
interface InitStatus {
    lottery: number;
    invite: number;
    hasInviteCode: boolean;
    liked: number;
    inviteCode: string;
    grid: [];
}

/// 开服裂变活动
const Kaifuliebian: React.FC = () => {

    const requestDomain = "";

    const activityId = "1";
    const ruleUrl = "https://wentrip.cn/h5/activity/kflb/rule"

    /// 定时器
    var drawingTimerIntervalId: any = null

    /// 【本地数据】
    /// 用来指定转动顺序的数组
    const runningArray: number[] = [0, 1, 2, 5, 8, 7, 6, 3, 4];
    /// 中间的素材
    const centerIcon = [jiugonggeObjectCenterIcon, jiugonggeObjectCenterRatioUp, jiugonggeObjectCenterRatioUpAgain, jiugonggeObjectCenterRatioBigUp, jiugonggeObjectCenterBizhong];

    /// 是否正在抽奖中
    const [isDrawing, setIsDrawing] = useState<boolean>(false);
    /// 是否加载中
    const [isLoading, setIsLoading] = useState<boolean>(true);
    /// 登录态相关
    const [token, setToken] = useState<string | undefined>(undefined);
    const [avatar, setAvatar] = useState<string>('');
    /// 轮替的 index
    const [runningIndex, setRunningIndex] = useState<number | undefined>(undefined);
    /// 选中的 index
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
    /// 弹窗样式
    const [popupType, setPopupType] = useState<KFLBPopupType | undefined>(undefined);

    /// 【接口数据】
    /// 九宫格内容
    const [blockDataList, setBlockDataList] = useState<KFLBBlockObject[]>([]);
    /// 可抽奖次数
    const [drawCount, setDrawCount] = useState<number>(0);
    /// 已邀请数
    const [invitedCount, setInvitedCount] = useState<number>(0);
    /// 邀请码
    const [inviteCode, setInviteCode] = useState<string>("");
    /// 是否已经填写邀请码
    const [hasFillInInviteCode, setHasFillInInviteCode] = useState<boolean>(false);
    /// 获赞次数
    const [praisedCount, setPraisedCount] = useState<number>(0);

    const [imageToShare, setImageToShare] = useState<string>("");

    useEffect(() => {

        setupWebViewJavascriptBridge((bridge: any) => {

            bridge.registerHandler('Wenlv_WebView_ShareSheetClosed', () => {
                setImageToShare("");
            })
        });
    }, []);

    useEffect(() => {
        /// token 为 undefined 时，登录并获取token
        /// token 有值时，获取活动状态，获取头像
        /// token 为空字符串时，展示重试弹窗
        if (token === undefined) {
            setup();
        } else if (token.length > 0) {
            fetchInitStatus(token);
            updateAvatar();
        } else {
            setPopupType(KFLBPopupType.Retry);
        }
    }, [token]);

    useEffect(() => {

        
        if (imageToShare.length > 0) {

             // 复制邀请码
            if (inviteCode.length === 0) { return; }
            setupWebViewJavascriptBridge((bridge: any) => {
                const text = inviteCode;
                console.log(`@   复制邀请码: ${inviteCode}`);
                bridge.callHandler("Hlhs_Copy", text, null);
            });

            const text = getInviteCodeCopyText();
            const object = {
                title: '',
                text: "", /// 弃用，兼容旧版本
                descr: "",
                imageBase64: imageToShare,
                link: '',
            };
            const objectJson = JSON.stringify(object);
            setupWebViewJavascriptBridge((bridge: any) => {
                bridge.callHandler('Hlhs_Share', objectJson, null);
            });
        }
    }, [imageToShare]);

    /// 初始化
    const setup = () => {

        clearInterval(drawingTimerIntervalId);
        setIsLoading(true);

        setupWebViewJavascriptBridge((bridge: any) => {

            bridge.callHandler('Hlhs_Login', null, (response: any) => {

                let data = handleBridgeResult(response);
                if (data === true) {

                    setupWebViewJavascriptBridge((bridge: any) => {

                        bridge.callHandler('Hlhs_Token', null, (response: any) => {

                            setIsLoading(false);
                            let data = handleBridgeResult(response) || '';
                            if (data.length > 0) {
                                setToken(data);
                            } else {
                                reset();
                                setPopupType(KFLBPopupType.Retry);
                            }
                        });
                    });
                } else {
                    setIsLoading(false);
                    reset();
                    setPopupType(KFLBPopupType.Retry);
                }
            });
        });
    }
    const reset = () => {

        clearInterval(drawingTimerIntervalId);
        setToken('');
        setAvatar('');
        setIsDrawing(false);
        setRunningIndex(undefined);
        setSelectedIndex(undefined);
        setPopupType(undefined);
        setBlockDataList([]);
        setDrawCount(0);
        setInvitedCount(0);
        setInviteCode("");
        setHasFillInInviteCode(false);
        setPraisedCount(0);
    }
    const fetchInitStatus = async (token: string) => {

        setIsLoading(true);

        try {

            const response = await fetch(`${requestDomain}/main/v1/fission`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            });

            setIsLoading(false);

            if (response.ok) {

                const result = await response.json();
                if (result.code === 200 && result.data) {

                    let status = (result.data as InitStatus);
                    setBlockDataList(status.grid); /// 九宫格
                    setDrawCount(status.lottery || 0); /// 抽奖次数
                    setInvitedCount(status.invite); /// 已邀请数
                    setInviteCode(status.inviteCode); /// 邀请码
                    setHasFillInInviteCode(status.hasInviteCode || false); /// 是否已经填写邀请码
                    setPraisedCount(status.liked); /// 被点赞数
                } else {
                    reset();
                }
            } else {
                reset();
            }
        } catch (err) {
            setIsLoading(false);
            reset();
        }
    }
    const redeem = async (inviteCode: string) => {

        setIsLoading(true);

        try {

            const response = await fetch(`${requestDomain}/main/v1/fission/redeem/${inviteCode}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token || '',
                },
            });

            setIsLoading(false);

            if (response.ok) {

                const result = await response.json();
                if (result.code === 200 && result.data) {
                    let count: number = result.data.lottery;
                    setDrawCount(count);
                    setHasFillInInviteCode(true);

                    toast("抽奖次数 +1");
                } else {
                    toast(result.message || "兑换失败，请重试...");
                }
            } else {
                toast("兑换失败，请重试...");
            }
        } catch (err) {
            setIsLoading(false);
            toast("兑换失败，请重试...");
        }
    }
    const updateAvatar = () => {

        setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('Hlhs_Avatar', null, (response: any) => {
                let data = handleBridgeResult(response);
                setAvatar(data || '');
            });
        });
    }

    /// 通用的
    const setupWebViewJavascriptBridge = (callback: any) => {
        return callback(window.WebViewJavascriptBridge);
    }

    const toast = (message: string) => {
        setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('Hlhs_Toast', message);
        });
    }

    const getStatusIcon = (status: KFLBBlockStatus) => {

        switch (status) {
            case KFLBBlockStatus.Hide:
                return "";
            case KFLBBlockStatus.Normal:
                return jiugonggeBgNormal;
            case KFLBBlockStatus.Runing:
                return jiugonggeBgRuning;
            case KFLBBlockStatus.Selected:
                return jiugonggeBgSelected;
        }
    }

    const getObjectIcon = (type: KFLBBlockObject) => {

        switch (type) {
            case KFLBBlockObject.Thanks:
                return jiugonggeObjectThanks;
            case KFLBBlockObject.RatioUp:
                return jiugonggeObjectRatioUp;
            case KFLBBlockObject.Xicha:
                return jiugonggeObjectXicha;
            case KFLBBlockObject.InstaxMiniSE:
                return jiugonggeObjectInstaxMiniSE;
            case KFLBBlockObject.DJIOsmoPocket3:
                return jiugonggeObjectDJIOsmoPocket3;

            case KFLBBlockObject.Center:
                const thanksCount = blockDataList.filter(data => { return data === KFLBBlockObject.Thanks }).length;
                const index = [4, 3, 2, 1, 0].indexOf(thanksCount);
                return centerIcon[index === -1 ? 0 : index];
        }
    }

    /// 交互
    const onClickDrawButton = () => { /// 抽奖

        if (isDrawing === true) { return; }
        if (drawCount <= 0) {
            setPopupType(KFLBPopupType.NotEnoughDrawCount);
            return;
        }

        setIsDrawing(true);
        setupDrawingTimer();

        let ms = [2000, 2500, 2200][Math.floor(Math.random() * 3)];
        setTimeout(() => {
            doDraw();
        }, ms);
    }
    const onClickCopyInviteCode = () => { /// 复制邀请码

        if (inviteCode.length === 0) { return; }
        setupWebViewJavascriptBridge((bridge: any) => {
            const text = getInviteCodeCopyText();
            bridge.callHandler("Hlhs_Copy", text, null);
        });
    }

    const getInviteCodeCopyText = () => {
        return `来【问旅】抽📹大疆Pocket3、📷拍立得、🥤喜茶卡啦!\n💡注册填写我的邀请码就能抽奖，20抽必中！\n我的邀请码：${inviteCode}`;
    }

    const onClickShareButton = () => { /// 分享海报
        share()
    }
    const onClickFillInInviteCodeButton = () => { /// 去填写

        setPopupType(KFLBPopupType.FillInInviteCode);
    }
    const onClickPostButton = () => { /// 去发布

        setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('Wenlv_Route', 'community/note/post?type=note', null);
        });
    }
    const onClickRuleButton = () => { /// 活动规则

        setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('Wenlv_Route', 'webview?url=' + ruleUrl + '&title=&ignoresSafeArea=0', null);
        });
    }
    const onClickMyAwardButton = () => { /// 我的奖品

        setupWebViewJavascriptBridge((bridge: any) => {
            bridge.callHandler('Wenlv_Route', `community/awards?activityId=${activityId}`, null);
        });
    }

    const share = async () => {

        setIsLoading(true);

        /// 文字配置
        const textConfig = [
            {
                text: inviteCode,
                x: 600,
                y: 2590,
                font: '600 150px Arial',
                color: 'white',
                align: 'center'
            },
            {
                text: inviteCode,
                x: 820,
                y: 4960,
                font: '600 110px Arial',
                color: 'black',
                align: 'center'
            },
        ];
        /// 图片配置
        const imageConfig = [
            {
                src: shareTemplateBg,
                x: 0,
                y: 0,
                width: 1200,
                height: 5191,
                radius: 0
            },
            {
                src: avatar,
                x: 398,
                y: 2318,
                width: 111,
                height: 111,
                radius: 55.5,
            },
            {
                src: avatar,
                x: 575,
                y: 4695,
                width: 111,
                height: 111,
                radius: 55.5,
            }
        ];
        const drawText = (ctx: CanvasRenderingContext2D, textConfig: any) => {
            textConfig.forEach((config: { text: any; x: any; y: any; font: any; color: any; align: any; }) => {
                const { text, x, y, font, color, align } = config;
                ctx.font = font;
                ctx.fillStyle = color;
                ctx.textAlign = align;
                ctx.fillText(text, x, y);
            });
        };
        const loadImage = (src: string) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'anonymous'; /// 解决头像跨域问题
                img.onload = () => resolve(img);
                img.onerror = reject;
                console.log(`kflb loadImage src = ${src}`);
                img.src = src;
            });
        };
        const drawImages = async (ctx: CanvasRenderingContext2D, imageConfig: any) => {
            try {
                for (const config of imageConfig) {
                    const img = await loadImage(config.src);
                    if (config.radius > 0) {
                        // 使用圆角绘制方法
                        drawRoundedImage(
                            ctx,
                            img,
                            config.x,
                            config.y,
                            config.width,
                            config.height,
                            config.radius
                        );
                    } else {
                        // 普通绘制方法
                        ctx.drawImage(img as CanvasImageSource, config.x, config.y, config.width, config.height);
                    }
                }
                return true;
            } catch (error) {
                console.log('Error loading images:', error);
                return false;
            }
        };
        // 绘制圆角矩形路径
        const drawRoundedRect = (ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number) => {
            ctx.beginPath();
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height - radius);
            ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
            ctx.lineTo(x + radius, y + height);
            ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
            ctx.closePath();
        };

        // 绘制带圆角的图片
        const drawRoundedImage = (ctx: CanvasRenderingContext2D, img: any, x: number, y: number, width: number, height: number, radius: number) => {
            ctx.save();
            drawRoundedRect(ctx, x, y, width, height, radius);
            ctx.clip();
            ctx.drawImage(img, x, y, width, height);
            ctx.restore();
        };

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            toast("分享失败，请稍后重试...")
            setIsLoading(false);
            return;
        }

        canvas.width = 1200;
        canvas.height = 5191;

        try {

            /// 绘制所有图片
            const imagesDrawn = await drawImages(ctx, imageConfig);
            if (imagesDrawn === false) {
                toast("头像处理失败...");
            }
            /// 绘制文字
            drawText(ctx, textConfig);

            /// 生成最终图片
            const dataURL = canvas.toDataURL('image/png');
            setImageToShare(dataURL);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast("生成分享信息失败，请稍后重试...");
        }
    };

    /// 启动抽奖中定时器
    const setupDrawingTimer = () => {

        let isFistTime = true; /// 第一次循环，从头开始
        drawingTimerIntervalId = setInterval(() => {

            setRunningIndex(prevIndex => {

                let preRealIndex = isFistTime ? -1 : prevIndex === undefined ? -1 : prevIndex;
                let realIndex = reduceRunningIndex(preRealIndex);
                isFistTime = false;
                return realIndex;
            });
        }, 200);

        return () => clearInterval(drawingTimerIntervalId);
    }

    /// 获取抽奖结果
    const doDraw = async () => {

        try {

            const response = await fetch(`${requestDomain}/main/v1/fission/lottery`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token || '',
                },
            });

            clearInterval(drawingTimerIntervalId);

            if (response.ok) {

                const result = await response.json();
                if (result.code === 200 && result.data) {

                    const indexToSelect = result.data.gridPosition;
                    setupSlowTimer(indexToSelect, () => {

                        clearInterval(drawingTimerIntervalId);

                        setRunningIndex(undefined);
                        setSelectedIndex(indexToSelect);
                        setDrawCount(result.data.lottery);

                        setTimeout(() => {
                            setIsDrawing(false);
                            setSelectedIndex(undefined);
                            handleSelectedIndex(indexToSelect, result.data.disGrid || [], result.data.lottery);
                        }, 300);
                    });
                } else {
                    setIsDrawing(false);
                    setRunningIndex(undefined);
                    toast(result.message || "抽奖失败，请重试...");
                }
            } else {
                setIsDrawing(false);
                setRunningIndex(undefined);
                toast("抽奖失败，请重试...");
            }
        } catch (err) {

            setIsDrawing(false);
            clearInterval(drawingTimerIntervalId);

            setRunningIndex(undefined);
            toast("抽奖失败，请重试...");
        }
    }
    /// 启动慢定时器，根据获取到的抽奖结果,继续跳转10次，再一个一个跳到抽中的位置
    const setupSlowTimer = (index: number, stopHandler: () => void) => {

        let shouldStop: boolean = false; /// 到最大循环次数后，再一个一个走到index
        let maxRoundCount: number = 10; /// 最大循环次数

        drawingTimerIntervalId = setInterval(() => {

            if (shouldStop) {
                stopHandler();
                return;
            }

            setRunningIndex(prevIndex => {

                let realIndex = reduceRunningIndex(prevIndex === undefined ? 0 : prevIndex);
                maxRoundCount -= 1;
                if (maxRoundCount <= 0 && realIndex === index) {
                    shouldStop = true;
                }
                return realIndex;
            });
        }, 480);

        return () => clearInterval(drawingTimerIntervalId);
    }

    /// 根据上一个选中项，计算下一个选中项
    const reduceRunningIndex = (prevIndex: number) => {

        let virtualIndex = runningArray.indexOf(prevIndex); /// 在 runningArray 中的 index
        virtualIndex = virtualIndex === -1 ? 0 : (virtualIndex + 1);
        while ([KFLBBlockObject.None, KFLBBlockObject.Center].includes(blockDataList[runningArray[virtualIndex]])) {
            virtualIndex = (virtualIndex + 1) % runningArray.length;
        }
        return runningArray[virtualIndex];
    }

    /// 累计禁用网格 disableGridIndexList
    /// 是否中奖 isWinner
    /// 剩余抽奖次数 drawCount
    const handleSelectedIndex = (index: number, disableGridIndexList: number[], drawCount: number) => {

        let oldList = blockDataList;
        let newList = oldList;
        for (let i = 0; i < newList.length; i++) {
            if (disableGridIndexList.includes(i)) {
                newList[i] = KFLBBlockObject.None;
            }
        }
        setBlockDataList(newList);

        switch (oldList[index]) {

            case KFLBBlockObject.Thanks:

                if (drawCount === 0) {
                    setPopupType(KFLBPopupType.ThanksWithoutDrawCount);
                } else {
                    setPopupType(KFLBPopupType.Thanks);
                }
                break;
            case KFLBBlockObject.RatioUp:

                const thanksCount = newList.filter(data => { return data === KFLBBlockObject.Thanks }).length;
                switch (thanksCount) {
                    case 0:
                        setPopupType(KFLBPopupType.RatioUpBizhong);
                        break;
                    case 1:
                        setPopupType(KFLBPopupType.RatioUpBig);
                        break;
                    case 2:
                        setPopupType(KFLBPopupType.RatioUpAgain);
                        break;
                    case 3:
                        setPopupType(KFLBPopupType.RatioUp);
                        break;
                    default:
                        setPopupType(KFLBPopupType.RatioUp);
                        break;
                }
                break;
            case KFLBBlockObject.Xicha:

                setPopupType(KFLBPopupType.AwardXicha);
                break;
            case KFLBBlockObject.InstaxMiniSE:

                setPopupType(KFLBPopupType.AwardInstaxMiniSE);
                break;
            case KFLBBlockObject.DJIOsmoPocket3:

                setPopupType(KFLBPopupType.AwardDJIOsmoPocket3);
                break;
            case KFLBBlockObject.Center:
            case KFLBBlockObject.None:
                break;
        }
    }

    /// UI：九宫格
    let jiugonggeElements = [];
    for (let i = 0; i < blockDataList.length; i++) {

        const data = blockDataList[i];
        const isHidden = blockDataList[i] === KFLBBlockObject.None;
        const isRunning = i === runningIndex;
        const isSelected = i === selectedIndex;
        const status = isHidden ? KFLBBlockStatus.Hide : isSelected ? KFLBBlockStatus.Selected : isRunning ? KFLBBlockStatus.Runing : KFLBBlockStatus.Normal;

        let bgIcon = getStatusIcon(status);
        let objectIcon = getObjectIcon(data);
        if (isHidden) {
            jiugonggeElements.push(
                <div key={i} className={styles.kflb_content_overlay_jiugongge_item}></div>
            )
        } else {

            jiugonggeElements.push(
                <div key={i} className={styles.kflb_content_overlay_jiugongge_item}>
                    {i !== 4 && <img className={styles.kflb_content_overlay_jiugongge_item_bg} src={bgIcon} alt="" />}
                    <img className={styles.kflb_content_overlay_jiugongge_item_object} src={objectIcon} alt="" />
                </div>
            );
        }
    }

    /// UI：邀请用户
    let inviteInfo = <div className={styles.kflb_content_overlay_info} style={{ marginTop: '21.70vw', height: '7.83vw' }}>
        <div className={styles.kflb_content_overlay_info_line1}>邀请新用户（已邀请： {invitedCount}）</div>
        <div className={styles.kflb_content_overlay_info_line2}>不限次数！邀请新用户填写你的邀请码</div>
    </div>

    /// UI：邀请码
    let inviteCodeElements = [];
    for (let i = 0; i < inviteCode.length; i++) {
        const number = inviteCode[i];
        inviteCodeElements.push(
            <div className={styles.kflb_content_overlay_invite_code_item}>{number}</div>
        );
    }

    /// UI：步骤一/邀请
    let inviteCodeStep = <div className={styles.kflb_content_overlay_step} style={{ marginTop: '3.19vw' }}>
        <div className={styles.kflb_content_overlay_info} style={{ height: '9.5vw' }}>
            <div className={styles.kflb_content_overlay_info_line1}>填写邀请码（{hasFillInInviteCode === true ? 1 : 0}/1）</div>
            <div className={styles.kflb_content_overlay_info_line2}>填写他人邀请码，双方都可获得次数</div>
        </div>
        <img className={styles.kflb_content_overlay_step_button} src={goFillInIcon} alt="" onClick={onClickFillInInviteCodeButton} />
        {hasFillInInviteCode === true && <img className={styles.kflb_content_overlay_step_completion} src={stepCompletion} alt="" />}
    </div>

    /// UI：步骤二/发布笔记
    let postNoteStep = <div className={styles.kflb_content_overlay_step} style={{ marginTop: '2.5vw' }}>
        <div className={styles.kflb_content_overlay_info} style={{ height: '9.5vw' }}>
            <div className={styles.kflb_content_overlay_info_line1}>发布内容获赞（点赞{praisedCount}/5）</div>
            <div className={styles.kflb_content_overlay_info_line2}>发布一篇内容并获得5个赞</div>
        </div>
        <img className={styles.kflb_content_overlay_step_button} src={goPostIcon} alt="" onClick={onClickPostButton} />
        {praisedCount >= 5 && <img className={styles.kflb_content_overlay_step_completion} src={stepCompletion} alt="" />}
    </div>

    /// 弹窗
    let popupContent = undefined;
    if (popupType !== undefined) {
        switch (popupType) {
            case KFLBPopupType.Thanks:
                popupContent = KFLBPopupThanks({
                    drawCount, closeHandler: () => {
                        setPopupType(undefined);
                    }, goDrawHandler: () => {
                        setPopupType(undefined);
                    }, shareHandler: () => {
                        setPopupType(undefined);
                        share();
                    }
                });
                break;
            case KFLBPopupType.ThanksWithoutDrawCount:
                popupContent = KFLBPopupThanks({
                    drawCount: 0, closeHandler: () => {
                        setPopupType(undefined);
                    }, goDrawHandler: () => {
                        setPopupType(undefined);
                    }, shareHandler: () => {
                        setPopupType(undefined);
                        share();
                    }
                });
                break;
            case KFLBPopupType.AwardXicha:
                popupContent = KFLBPopupAward({
                    object: KFLBBlockObject.Xicha, knowHandler: () => {
                        setPopupType(undefined);
                        fetchInitStatus(token || "");
                    }
                });
                break;
            case KFLBPopupType.AwardInstaxMiniSE:
                popupContent = KFLBPopupAward({
                    object: KFLBBlockObject.InstaxMiniSE, knowHandler: () => {
                        setPopupType(undefined);
                        fetchInitStatus(token || "");
                    }
                });
                break;
            case KFLBPopupType.AwardDJIOsmoPocket3:
                popupContent = KFLBPopupAward({
                    object: KFLBBlockObject.DJIOsmoPocket3, knowHandler: () => {
                        setPopupType(undefined);
                        fetchInitStatus(token || "");
                    }
                });
                break;
            case KFLBPopupType.RatioUp:
            case KFLBPopupType.RatioUpAgain:
            case KFLBPopupType.RatioUpBig:
            case KFLBPopupType.RatioUpBizhong:
                popupContent = KFLBPopupRatioUp({
                    object: popupType, continueDrawHandler: () => {
                        setPopupType(undefined);
                    }
                });
                break;
            case KFLBPopupType.NotEnoughDrawCount:
                popupContent = KFLBPopupNotEnoughDrawCount({
                    closeHandler: () => {
                        setPopupType(undefined);
                    }, goShareHandler: () => {
                        setPopupType(undefined);
                        share();
                    }
                });
                break;
            case KFLBPopupType.FillInInviteCode:
                popupContent = KFLBPopupFillInInviteCode({
                    closeHandler: () => {
                        setPopupType(undefined);
                    }, confirmHandler: (code) => {
                        setPopupType(undefined);
                        redeem(code);
                    }
                });
                break;
            case KFLBPopupType.Retry:
                popupContent = KFLBPopupRetry({
                    confirmHandler: () => {
                        setPopupType(undefined);
                        setup();
                    }
                });
                break;
        }
    }

    const showShare: boolean = imageToShare.length > 0;

    return (
        <div className={styles.kflb} style={showShare ? { height: '100vh', overflow: 'hidden' } : {}}>
            <div className={`${styles.kflb_content} ${showShare ? styles.blur : ''}`}>
                <div className={styles.kflb_content_bg}>
                    <img className={styles.kflb_content_bg_image} src={bg1} alt="" />
                    <img className={styles.kflb_content_bg_image} src={bg2} alt="" />
                    <img className={styles.kflb_content_bg_image} src={bg3} alt="" />
                    <img className={styles.kflb_content_bg_image} src={bg4} alt="" />
                </div>
                <div className={styles.kflb_content_overlay}>
                    <div className={styles.kflb_content_overlay_jiugongge}>
                        {jiugonggeElements}
                    </div>
                    <div className={styles.kflb_content_overlay_draw_count}>可抽奖次数：{drawCount}</div>
                    <img className={styles.kflb_content_overlay_draw_button} src={drawButtonIcon} alt="" onClick={onClickDrawButton} />
                    {inviteInfo}
                    <div className={styles.kflb_content_overlay_invite_tips} onClick={onClickCopyInviteCode}>
                        <img className={styles.kflb_content_overlay_invite_tips_avatar} src={avatar} alt=""></img>
                        <div className={styles.kflb_content_overlay_invite_tips_text}>点击复制邀请码</div>
                    </div>
                    <div className={styles.kflb_content_overlay_invite_code} onClick={onClickCopyInviteCode}>
                        {inviteCodeElements}
                    </div>
                    <img className={styles.kflb_content_overlay_share_button} src={shareIcon} alt="" onClick={onClickShareButton} />
                    {inviteCodeStep}
                    {postNoteStep}
                    <div className={styles.kflb_content_overlay_bottom_buttons}>
                        <img className={styles.kflb_content_overlay_bottom_buttons_item} src={activityRuleIcon} alt="" onClick={onClickRuleButton} />
                        <img className={styles.kflb_content_overlay_bottom_buttons_item} src={myAwardIcon} alt="" onClick={onClickMyAwardButton} />
                    </div>
                </div>
                {popupContent !== undefined && popupContent}
                {isDrawing === true && <div className={styles.kflb_content_drawing} onClick={() => { }} />}
                {isLoading === true && isDrawing === false && <div className={styles.kflb_content_loading} onClick={() => { }}>
                    <div style={{ borderRadius: '5px', padding: '10px', color: 'white', backgroundColor: 'rgb(0,0,0,0.6)' }}>加载中...</div>
                </div>}
            </div>
            {showShare && <div className={styles.kflb_content_share} onClick={() => setImageToShare('')}>
                <img className={styles.kflb_content_share_image} src={imageToShare} alt="" />
            </div>}
        </div>
    );
};

/// 弹窗：感谢参与
const KFLBPopupThanks: React.FC<{ drawCount: number, closeHandler: () => void, goDrawHandler: () => void, shareHandler: () => void }> = ({ drawCount, closeHandler, goDrawHandler, shareHandler }) => {

    return (
        <div className={styles.kflb_content_popup}>
            <div className={styles.kflb_content_popup_content_red}>
                {drawCount === 0 && <img style={{ position: 'absolute', top: '6vw', right: '6vw', width: '8.66vw', height: 'auto' }} src={iconPopupCloseIcon} alt="" onClick={closeHandler} />}
                <img style={{ width: '50.66vw', height: 'auto', marginTop: '19.16vw' }} src={jiugonggeObjectThanks} alt="" />
                <div style={{ height: '15vw', marginTop: '-9.333vw', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {drawCount === 0 && <div style={{ color: 'white', fontWeight: 600, fontSize: '5.33vw' }}>邀请新用户填写你的邀请码</div>}
                    {drawCount === 0 && <div style={{ color: 'white', fontWeight: 600, fontSize: '5.33vw' }}>可获得抽奖次数哦！</div>}
                </div>
                <div style={{ marginTop: '12.333vw', color: 'white', fontWeight: 600, fontSize: '4.16vw' }}>剩余抽奖次数：{drawCount}</div>
                {KFLBPopupButton({
                    marginTop: '2.75vw', textWidth: drawCount === 0 ? '16.33vw' : '22.33vw', textSrc: drawCount === 0 ? textPopupShareIcon : textPopupContinueDrawIcon, clickHandler: () => {
                        drawCount === 0 ? shareHandler() : goDrawHandler();
                    }
                })}
            </div>
        </div>
    );
}

/// 弹窗：中奖啦
const KFLBPopupAward: React.FC<{ object: KFLBBlockObject, knowHandler: () => void }> = ({ object, knowHandler }) => {

    let awardName = "";
    let awardImg = undefined;
    switch (object) {
        case KFLBBlockObject.Xicha:
            awardName = "喜茶30元喜卡";
            awardImg = <img style={{ width: '52.3vw' }} src={jiugonggeObjectXicha} alt="" />;
            break;
        case KFLBBlockObject.InstaxMiniSE:
            awardName = "富士拍立得 instax mini12";
            awardImg = <img style={{ width: '52.3vw' }} src={jiugonggeObjectInstaxMiniSE} alt="" />;
            break;
        case KFLBBlockObject.DJIOsmoPocket3:
            awardName = "DJI Osmo Pocket3";
            awardImg = <img style={{ width: '52.3vw', height: '57.7vw' }} src={iconPopupAwardDjiIcon} alt="" />;
            break;
    }

    return (
        <div className={styles.kflb_content_popup}>
            <div className={styles.kflb_content_popup_content_red_award}>
                <div style={{ height: '11.8vw', marginTop: '29.333vw', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ color: 'white', fontWeight: 600, fontSize: '4vw' }}>恭喜你！获得{awardName}</div>
                    <div style={{ color: 'white', fontWeight: 500, fontSize: '4vw' }}>已放入“我的奖品”，请及时查看</div>
                </div>
                <div style={{ width: '52.3vw', height: '57.7vw', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {awardImg}
                </div>
                {KFLBPopupButton({ marginTop: '5.20833vw', textWidth: '24.58vw', textSrc: textPopupIKnowIcon, clickHandler: knowHandler })}
            </div>
        </div>
    );
}

/// 弹窗：概率提升
const KFLBPopupRatioUp: React.FC<{ object: KFLBPopupType, continueDrawHandler: () => void }> = ({ object, continueDrawHandler }) => {

    let img = "";
    switch (object) {
        case KFLBPopupType.RatioUp:
            img = popupRatioUpIcon;
            break;
        case KFLBPopupType.RatioUpBig:
            img = popupRatioUpBigIcon;
            break;
        case KFLBPopupType.RatioUpAgain:
            img = popupRatioUpAgainIcon;
            break;
        case KFLBPopupType.RatioUpBizhong:
            img = popupRatioUpBizhongIcon;
            break;
    }

    return (
        <div className={styles.kflb_content_popup}>
            <div className={styles.kflb_content_popup_content_red}>
                <div style={{ marginTop: '12.08vw', height: '5.58vw', color: 'white', fontWeight: 600, fontSize: '4vw' }}>恭喜你！中奖概率提升</div>
                <img style={{ position: 'absolute', top: '54vw', left: '5.3vw', width: '32vw', zIndex: 1 }} src={popupRatioUpFace1} alt="" />
                <img style={{ marginTop: '3.33vw', width: '44vw', zIndex: 2 }} src={img} alt="" />
                <img style={{ position: 'absolute', top: '54vw', right: '5.3vw', width: '39vw', zIndex: 3 }} src={popupRatioUpFace2} alt="" />
                {KFLBPopupButton({ marginTop: '36.66vw', textWidth: '22.33vw', textSrc: textPopupContinueDrawIcon, clickHandler: continueDrawHandler })}
            </div>
        </div>
    );
}

/// 弹窗：没有抽奖次数
const KFLBPopupNotEnoughDrawCount: React.FC<{ closeHandler: () => void, goShareHandler: () => void }> = ({ closeHandler, goShareHandler }) => {

    return (
        <div className={styles.kflb_content_popup}>
            <div className={styles.kflb_content_popup_content_red}>
                <img style={{ position: 'absolute', top: '6vw', right: '6vw', width: '8.66vw', height: 'auto' }} src={iconPopupCloseIcon} alt="" onClick={closeHandler} />
                <div style={{ marginTop: '25vw', height: '12.83vw', color: 'white', fontWeight: 600, fontSize: '9.16vw' }}>没有抽奖次数了!</div>
                <div style={{ height: '15vw', marginTop: '5vw', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ color: 'white', fontWeight: 600, fontSize: '5.33vw' }}>邀请新用户填写你的邀请码</div>
                    <div style={{ color: 'white', fontWeight: 600, fontSize: '5.33vw' }}>可获得抽奖次数哦！</div>
                </div>
                {KFLBPopupButton({ marginTop: '31.5vw', textWidth: '16.33vw', textSrc: textPopupShareIcon, clickHandler: goShareHandler })}
            </div>
        </div>
    );
}

/// 弹窗：填写邀请码
const KFLBPopupFillInInviteCode: React.FC<{ closeHandler: () => void, confirmHandler: (code: string) => void }> = ({ closeHandler, confirmHandler }) => {

    return (
        <div className={styles.kflb_content_popup}>
            <div className={styles.kflb_content_popup_content_white}>
                <img style={{ position: 'absolute', top: '6vw', right: '6vw', width: '8.66vw', height: 'auto' }} src={iconPopupCloseBlackIcon} alt="" onClick={closeHandler} />
                <div style={{ marginTop: '23.41vw', height: '12.83vw', color: 'black', fontWeight: 600, fontSize: '9.16vw' }}>填写邀请码</div>
                <div style={{ marginTop: '2.91vw', height: '', color: 'black', fontWeight: 600, fontSize: '4.515vw' }}>填写他人邀请码，获得抽奖次数</div>
                <input id="kflb_popup_input_invite_code" placeholder="输入邀请码" style={{ color: 'black', textAlign: 'center', marginTop: '6.833vw', width: '66.83vw', height: '16.75vw', backgroundColor: '#EAEAEA', borderRadius: '4.58vw', fontSize: '4.5vw', fontWeight: '600', borderColor: 'transparent' }}></input>
                <img style={{ marginTop: '25.5vw', width: '47.4vw' }} src={popupConfirmButtonIcon} alt="" onClick={() => {

                    const inputElement = document.getElementById("kflb_popup_input_invite_code");
                    if (inputElement) {
                        const inputValue = (inputElement as HTMLInputElement).value;
                        if (inputValue && inputValue.length > 0) {
                            confirmHandler(inputValue);
                        }
                    }
                }} />
            </div>
        </div>
    );
}

/// 弹窗：重试
const KFLBPopupRetry: React.FC<{ confirmHandler: () => void }> = ({ confirmHandler }) => {

    return (
        <div className={styles.kflb_content_popup}>
            <div className={styles.kflb_content_popup_content_white}>
                <div style={{ marginTop: '50.25vw', height: '6.333vw', color: 'black', fontWeight: 600, fontSize: '4.515vw' }}>获取活动失败，请重试...</div>
                <img style={{ marginTop: '38.08vw', width: '47.4vw' }} src={popupConfirmButtonIcon} alt="" onClick={confirmHandler} />
            </div>
        </div>
    );
}

/// 弹窗通用按钮
const KFLBPopupButton: React.FC<{ marginTop: string, textWidth: string, textSrc: string, clickHandler: () => void }> = ({ marginTop, textWidth, textSrc, clickHandler }) => {

    return <div style={{ position: 'relative', marginTop: marginTop, width: '45.666vw', height: '9.29vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} onClick={clickHandler}>
        <img style={{ position: 'absolute', top: 0, left: 0, width: '45.666vw' }} src={bgPopupButtonIcon} alt="" />
        <img style={{ width: textWidth, zIndex: 1 }} src={textSrc} alt="" />
    </div>
}

export default Kaifuliebian;
